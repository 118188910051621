<template>
    <div>
        <h2 class="my-2">Teams</h2>
        <v-expansion-panels>
          <v-expansion-panel v-for="(team,i) in teams" :key="i" class="my-2">
            <v-expansion-panel-header class="indigo lighten-5 font-weight-black">{{team.age}}</v-expansion-panel-header>
            <v-expansion-panel-content class="mt-4">
              <template>
                <v-row>
                  <v-col>
                    <v-dialog
                      v-model="dialog"
                      persistent
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small elevation="1" class="deep-orange white--text" v-bind="attrs" v-on="on"><i class="fas fa-plus me-1"></i>Add Player</v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="color2 white--text">
                          <span class="text-h5">Add Player</span>
                        </v-card-title>
                        <v-card-text>
                          <v-alert
                            color="red"
                            dense
                            type="error"
                            class="mt-4 mb-0"
                            v-if="newPlayerAlert"
                          >Please fill in the fields for <span v-for="(obj, i) in newPlayerErrors" :key="i">{{ obj }}<span v-if="i != Object.keys(newPlayerErrors).length - 1">, </span></span>
                          </v-alert>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  label="First Name *"
                                  required
                                  v-model="newPlayer.firstName"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="Last Name *"
                                  required
                                  v-model="newPlayer.lastName"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-menu
                                    v-model="fromDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        label="DOB *"
                                        readonly
                                        required
                                        :value="fromDateDisp"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      locale="en-in"
                                      v-model="newPlayer.dob"
                                      no-title
                                      @input="fromDateMenu = false"
                                      :min="minDate"
                                    ></v-date-picker>
                                  </v-menu>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <v-autocomplete
                                  :items="['OH', 'OPP', 'S', 'MB', 'DS', 'L']"
                                  label="Position *"
                                  required
                                  multiple
                                  v-model="newPlayer.position"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  label="Number"
                                  type="number"
                                  v-model="newPlayer.number"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  label="City *"
                                  required
                                  v-model="newPlayer.city"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  :items="['CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID']"
                                  label="State *"
                                  required
                                  v-model="newPlayer.state"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  label="AAU Membership"
                                  v-model="newPlayer.aauMembership"
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="USAV Membership"
                                  v-model="newPlayer.usavMembership"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          <small>* indicates required field</small>
                        </v-card-text>
                        <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            Close
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="savePlayer(newPlayer, team.age)"
                          >
                            Add Player
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="headers"
                  :items="team.players"
                  :items-per-page="15"
                  class="elevation-1"
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="(item,i) in items" :key="i" class="table-row">
                          <td class="">
                            <span v-if="!item.edit">{{ item.firstName }}</span>
                            <v-text-field v-else single-line :value="item.firstName"></v-text-field></td>
                          <td class="">
                            <span v-if="!item.edit">{{ item.lastName }}</span>
                            <v-text-field v-else single-line :value="item.lastName"></v-text-field></td>
                          <td class="">
                            <span v-if="!item.edit">{{ item.dob }}</span>
                            <v-menu v-else
                              v-model="fromDateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  :value="item.dob"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                locale="en-in"
                                v-model="item.dob"
                                :value="item.dob"
                                no-title
                                @input="fromDateMenu = false"
                                :min="minDate"
                              ></v-date-picker>
                            </v-menu>
                          </td>
                          <td class="">
                            <div v-if="!item.edit">
                              <span v-for="( pos, i ) in item.position" :key="i">{{ pos }} </span>
                            </div>
                            <v-autocomplete v-else
                                  :items="['OH', 'OPP', 'S', 'MB', 'DS', 'L']"
                                  multiple
                                  v-model="item.position"
                                ></v-autocomplete>
                          </td>
                          <td class="">
                            <span v-if="!item.edit">{{ item.number }}</span>
                            <v-text-field v-else single-line :value="item.number" type="number"></v-text-field>
                          </td>
                          <td class="">{{ item.aauMembership }}
                            <span class="ms-1" v-if="item.aauStatus"><i class="fas fa-check-circle green--text"></i></span>
                            <span class="ms-1" v-else>
                              <v-btn elevation="0" x-small rounded color="amber"><i class="fas fa-exclamation-triangle me-1"></i>Expired</v-btn>
                            </span>
                          </td>
                          <td class="">{{ item.usavMembership }}
                            <span class="ms-1" v-if="item.usavStatus"><i class="fas fa-check-circle green--text"></i></span>
                            <span class="ms-1" v-else>
                              <v-btn elevation="0" x-small rounded color="amber"><i class="fas fa-exclamation-triangle me-1"></i>Expired</v-btn>
                            </span>
                          </td>
                          <td class="">
                            <v-menu v-if="!item.edit" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn elevation="0" large text v-bind="attrs" v-on="on" class="p-0"><i class="fas fa-ellipsis-h color2--text"></i></v-btn>
                              </template>
                              <v-list class="py-0">
                                <v-list-item
                                  v-for="( a, index ) in actions"
                                  :key="index"
                                >
                                  <v-list-item-title v-if="a === 'Edit'" class="text-button" @click="editField(team.age, i)"><i class="fas fa-pencil me-2"></i>{{ a }}</v-list-item-title>
                                  <v-list-item-title v-else-if="a === 'Move'" class="text-button"><i class="fas fa-arrows-v me-2"></i>{{ a }}</v-list-item-title>
                                  <v-list-item-title v-else class="text-button"><i class="fas fa-trash me-2"></i>{{ a }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            <v-btn-toggle v-else background-color="white">
                              <v-btn small>
                                <i class="fas fa-times-circle red--text" @click="cancelPlayerUpdate(team.age, i)"></i>
                              </v-btn>
                              <v-btn small>
                                <i class="fas fa-save green--text" @click="updatePlayer(team.age, item, i)"></i>
                              </v-btn>
                            </v-btn-toggle>
                          </td>
                        </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  props: [],
  data () {
    return {
      dialog: false,
      fromDateMenu: false,
      fromDateVal: null,
      minDate: '1999-01-01',
      maxDate: '2015-01-01',
      newPlayer: {
        firstName: '', firstNameReq: false, lastName: '', lastNameReq: false, dob: '', dobReq: false, position: '', positionReq: false, number: '', aauMembership: '', usavMembership: ''
      },
      newPlayerAlert: false,
      newPlayerErrors: [],
      actions: [
        'Edit', 'Move', 'Delete'
      ],
      headers: [
        { text: 'First Name', value: 'firstName', class: 'indigo lighten-4' },
        { text: 'Last Name', value: 'lastName', class: 'indigo lighten-4' },
        { text: 'DOB', value: 'dob', class: 'indigo lighten-4' },
        { text: 'Position', value: 'position', class: 'indigo lighten-4' },
        { text: 'Number', value: 'number', class: 'indigo lighten-4' },
        { text: 'AAU Membership', value: 'aauMembership', class: 'indigo lighten-4' },
        { text: 'USAV Membership', value: 'usavMembership', class: 'indigo lighten-4' },
        { text: 'Actions', value: 'actions', class: 'indigo lighten-4' }
      ],
      teams: [
        {
          age: 'U13-1',
          coach: '',
          assistantOne: '',
          assistantTwo: '',
          players: [
            { edit: false, firstName: 'Annika', lastName: 'Engen', position: ['OH', 'OPP'], number: 2, dob: '1999-12-14', city: 'Ashburn', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Sarah', lastName: 'James', position: ['MB'], number: 10, dob: '1999-12-14', city: 'Easton', state: 'PA', aauMembership: '1029-123', aauStatus: false, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Ellen', lastName: 'Noh', position: ['S'], number: 5, dob: '1999-12-14', city: 'Oakton', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Anna', lastName: 'Suto', position: ['S'], number: 6, dob: '1999-12-14', city: 'Germantown', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Ijenna', lastName: 'Mere', position: ['MB'], number: 21, dob: '1999-12-14', city: 'Ellicott City', state: 'MD', aauMembership: '1029-123', aauStatus: false, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Kaelyn', lastName: 'Anson', position: ['L'], number: 8, dob: '1999-12-14', city: 'Kensington', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true }
          ]
        },
        {
          age: 'U14-1',
          coach: '',
          assistantOne: '',
          assistantTwo: '',
          players: [
            { edit: false, firstName: 'Mic', lastName: 'Svenson', position: ['MB'], number: 10, dob: '1999-12-14', city: 'Ashburn', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: false },
            { edit: false, firstName: 'Anna', lastName: 'Adiaconitei', position: ['DS'], number: 3, dob: '1999-12-14', city: 'Easton', state: 'PA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Sophie', lastName: 'Galanova', position: ['OH'], number: 13, dob: '1999-12-14', city: 'Oakton', state: 'VA', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Lexi', lastName: 'Allouche', position: ['OPP'], number: 15, dob: '1999-12-14', city: 'Germantown', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Lorelei', lastName: 'Morice', position: ['MB'], number: 9, dob: '1999-12-14', city: 'Ellicott City', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true },
            { edit: false, firstName: 'Hannah', lastName: 'Kim', position: ['L'], number: 1, dob: '1999-12-14', city: 'Kensington', state: 'MD', aauMembership: '1029-123', aauStatus: true, usavMembership: '10-109-123', usavStatus: true }
          ]
        }
      ]
    }
  },
  computed: {
    fromDateDisp () {
      return this.newPlayer.dob
      // format/do something with date
    }
  },
  methods: {
    savePlayer (data, teamAge) {
      this.newPlayerErrors = []
      if (data.firstName === '') {
        this.newPlayerErrors.push('First Name')
      }
      if (data.lastName === '') {
        this.newPlayerErrors.push('Last Name')
      }
      if (data.dob === '') {
        this.newPlayerErrors.push('DOB')
      }
      if (data.position === '') {
        this.newPlayerErrors.push('Position')
      }
      if (data.city === '') {
        this.newPlayerErrors.push('City')
      }
      if (data.state === '') {
        this.newPlayerErrors.push('State')
      }
      if (this.newPlayerErrors.length > 0) {
        this.newPlayerAlert = true
      } else {
        this.newPlayerAlert = false
        this.dialog = false
      }
      const whichTeam = this.teams.find(t => t.age === teamAge)
      whichTeam.players.push(data)
      this.newPlayer = {
        firstName: '', firstNameReq: false, lastName: '', lastNameReq: false, dob: '', dobReq: false, position: '', positionReq: false, number: '', aauMembership: '', usavMembership: ''
      }
    },
    editField (age, index) {
      const team = this.teams.find(t => t.age === age)
      team.players[index].edit = true
    },
    cancelPlayerUpdate (age, index) {
      const team = this.teams.find(t => t.age === age)
      team.players[index].edit = false
    },
    updatePlayer (age, data, index) {
      const team = this.teams.find(t => t.age === age)
      const updated = team.players[index] = data
      updated.edit = false
    }
  },
  watch: {},
  mounted () {},
  components: {}
}
</script>

<style scoped>
.table-row {
  display: table-row;
}
</style>
